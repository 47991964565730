/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCampaignRequest = /* GraphQL */ `
  query GetCampaignRequest($id: ID!) {
    getCampaignRequest(id: $id) {
      id
      name
      description
      createdByUser
      modifiedByUser
      emailRequestStatus
      requesterName
      requesterEmail
      emailType
      campaignName
      timeZone
      audience
      seedlist
      approvalList
      subjectLine
      ctaLink
      goal
      legalSignoff
      awardsAndAccolades
      campaignAssets
      createdAt
      updatedAt
    }
  }
`;
export const listCampaignRequests = /* GraphQL */ `
  query ListCampaignRequests(
    $filter: ModelCampaignRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaignRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        createdByUser
        modifiedByUser
        emailRequestStatus
        requesterName
        requesterEmail
        emailType
        campaignName
        timeZone
        audience
        seedlist
        approvalList
        subjectLine
        ctaLink
        goal
        legalSignoff
        awardsAndAccolades
        campaignAssets
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMarketingEvent = /* GraphQL */ `
  query GetMarketingEvent($id: ID!) {
    getMarketingEvent(id: $id) {
      id
      createdByUser
      modifiedByUser
      eventCreator
      eventType
      eventShortName
      eventTitle
      vehicleOnDisplay
      eventVenueName
      eventAddressLine1
      eventAddressLine2
      eventAddressCity
      eventAddressState
      eventAddressZipCode
      prizeIndicator
      eventPublicImage
      createdAt
      updatedAt
    }
  }
`;
export const listMarketingEvents = /* GraphQL */ `
  query ListMarketingEvents(
    $filter: ModelMarketingEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarketingEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdByUser
        modifiedByUser
        eventCreator
        eventType
        eventShortName
        eventTitle
        vehicleOnDisplay
        eventVenueName
        eventAddressLine1
        eventAddressLine2
        eventAddressCity
        eventAddressState
        eventAddressZipCode
        prizeIndicator
        eventPublicImage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
