/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:2568b75a-3d6c-4200-b71c-234b71f71bb3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Z8MiGgAKf",
    "aws_user_pools_web_client_id": "578r8e9mlqevc0tkeskkvtn32u",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://njoruudhkjhz5hdzhcxaoye3gi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-5zali34t4nfv7fnh4nobjrzlvm",
    "aws_user_files_s3_bucket": "mst-storage-bucket182037-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
