/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCampaignRequest = /* GraphQL */ `
  mutation CreateCampaignRequest(
    $input: CreateCampaignRequestInput!
    $condition: ModelCampaignRequestConditionInput
  ) {
    createCampaignRequest(input: $input, condition: $condition) {
      id
      name
      description
      createdByUser
      modifiedByUser
      emailRequestStatus
      requesterName
      requesterEmail
      emailType
      campaignName
      timeZone
      audience
      seedlist
      approvalList
      subjectLine
      ctaLink
      goal
      legalSignoff
      awardsAndAccolades
      campaignAssets
      createdAt
      updatedAt
    }
  }
`;
export const updateCampaignRequest = /* GraphQL */ `
  mutation UpdateCampaignRequest(
    $input: UpdateCampaignRequestInput!
    $condition: ModelCampaignRequestConditionInput
  ) {
    updateCampaignRequest(input: $input, condition: $condition) {
      id
      name
      description
      createdByUser
      modifiedByUser
      emailRequestStatus
      requesterName
      requesterEmail
      emailType
      campaignName
      timeZone
      audience
      seedlist
      approvalList
      subjectLine
      ctaLink
      goal
      legalSignoff
      awardsAndAccolades
      campaignAssets
      createdAt
      updatedAt
    }
  }
`;
export const deleteCampaignRequest = /* GraphQL */ `
  mutation DeleteCampaignRequest(
    $input: DeleteCampaignRequestInput!
    $condition: ModelCampaignRequestConditionInput
  ) {
    deleteCampaignRequest(input: $input, condition: $condition) {
      id
      name
      description
      createdByUser
      modifiedByUser
      emailRequestStatus
      requesterName
      requesterEmail
      emailType
      campaignName
      timeZone
      audience
      seedlist
      approvalList
      subjectLine
      ctaLink
      goal
      legalSignoff
      awardsAndAccolades
      campaignAssets
      createdAt
      updatedAt
    }
  }
`;
export const createMarketingEvent = /* GraphQL */ `
  mutation CreateMarketingEvent(
    $input: CreateMarketingEventInput!
    $condition: ModelMarketingEventConditionInput
  ) {
    createMarketingEvent(input: $input, condition: $condition) {
      id
      createdByUser
      modifiedByUser
      eventCreator
      eventType
      eventShortName
      eventTitle
      vehicleOnDisplay
      eventVenueName
      eventAddressLine1
      eventAddressLine2
      eventAddressCity
      eventAddressState
      eventAddressZipCode
      prizeIndicator
      eventPublicImage
      createdAt
      updatedAt
    }
  }
`;
export const updateMarketingEvent = /* GraphQL */ `
  mutation UpdateMarketingEvent(
    $input: UpdateMarketingEventInput!
    $condition: ModelMarketingEventConditionInput
  ) {
    updateMarketingEvent(input: $input, condition: $condition) {
      id
      createdByUser
      modifiedByUser
      eventCreator
      eventType
      eventShortName
      eventTitle
      vehicleOnDisplay
      eventVenueName
      eventAddressLine1
      eventAddressLine2
      eventAddressCity
      eventAddressState
      eventAddressZipCode
      prizeIndicator
      eventPublicImage
      createdAt
      updatedAt
    }
  }
`;
export const deleteMarketingEvent = /* GraphQL */ `
  mutation DeleteMarketingEvent(
    $input: DeleteMarketingEventInput!
    $condition: ModelMarketingEventConditionInput
  ) {
    deleteMarketingEvent(input: $input, condition: $condition) {
      id
      createdByUser
      modifiedByUser
      eventCreator
      eventType
      eventShortName
      eventTitle
      vehicleOnDisplay
      eventVenueName
      eventAddressLine1
      eventAddressLine2
      eventAddressCity
      eventAddressState
      eventAddressZipCode
      prizeIndicator
      eventPublicImage
      createdAt
      updatedAt
    }
  }
`;
