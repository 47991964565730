import { withAuthenticator, SelectField, Flex, View, TextField  } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import './App.css';
import { createCampaignRequest as createNoteMutation, deleteCampaignRequest as deleteNoteMutation } from './graphql/mutations';
import { listCampaignRequests as listNotes } from './graphql/queries';
import { getTypeEnumeration as queryEnumerations } from './graphql/enumQueries';

const initialFormState = { name: '', description: '',  emailRequestStatus: ''}

function App({ signOut }) {
  const [notes, setNotes] = useState([]);
  const [enums, setEnums] = useState([]);
  const [formData, setFormData] = useState(initialFormState);
  

  useEffect(() => { 
    fetchNotes();
    //queryEnum("EmailRequestStatusType");
    queryEnum("CreativeStatusType");
  }, []);

  async function fetchNotes() {
    const apiData = await API.graphql({ query: listNotes });
    setNotes(apiData.data.listCampaignRequests.items);
  }

  async function queryEnum(name) {
    //const apiData = await API.graphql({ query: queryEnumerations, variables: { name: { name } } });
    const apiData = await API.graphql({ query: queryEnumerations, variables: { name:  "EmailRequestStatusType" } });
    setEnums(apiData.data.__type.enumValues);
    const apiData2 = await API.graphql({ query: queryEnumerations, variables: { name:  "CreativeRequestStatusType" } });
    setEnums(apiData2.data.__type.enumValues);
    //.then((res) => {
    //  console.log(res.data);
    //})
    //.catch((err) => console.log(err));
    //alert("["+name+"]");
  }

  async function createNote() {
    if (!formData.name || !formData.description) return;
    await API.graphql({ query: createNoteMutation, variables: { input: formData } });
    setNotes([ ...notes, formData ]);
    setFormData(initialFormState);
  }

  async function deleteNote({ id }) {
    const newNotesArray = notes.filter(note => note.id !== id);
    setNotes(newNotesArray);
    await API.graphql({ query: deleteNoteMutation, variables: { input: { id } }});
  }
  return (
    <div className="App">
      <h1>My Notes App</h1>
      <View>
      <Flex direction="column">
      <TextField
  descriptiveText="Who is requesting this campaign?"
  placeholder="Requester Name"
  label="Requester Name" onChange={e => setFormData({ ...formData, "requestorName": e.target.value})}
  errorMessage="There is an error"
/>
    <SelectField
      label="Email Request Status"
      descriptiveText="Please select a status."
      options={enums.map(item => item.name)}
    ></SelectField>
  </Flex>
  </View>
      <input
        onChange={e => setFormData({ ...formData, 'name': e.target.value})}
        placeholder="Note name"
        value={formData.name}
      />
      <input
        onChange={e => setFormData({ ...formData, 'description': e.target.value})}
        placeholder="Note description"
        value={formData.description}
      />
      <input
        onChange={e => setFormData({ ...formData, 'emailRequestStatus': e.target.value})}
        placeholder="Email Request Status"
        value={formData.emailRequestStatus}
      />
                Email Request Status:
          <select value={formData.emailRequestStatus} onChange={e => setFormData({ ...formData, 'emailRequestStatus': e.target.value})}>
            {
                  enums.map(item => (
                <option key={item.name}>{item.name}</option>))
              }
          </select> 
      <button onClick={createNote}>Create Note</button>
      <div style={{marginBottom: 30}}>
        {
          notes.map(note => (
            <div key={note.id || note.name}>
              <h2>{note.name}</h2>
              <p>{note.description}</p>
              <p>{note.emailRequestStatus}</p>
              <p>{note.requestorName}</p>
              <button onClick={() => deleteNote(note)}>Delete note</button>
            </div>
          ))
        }
      </div>
      <button onClick={signOut}>Sign Out</button>
    </div>);
}

export default withAuthenticator(App);