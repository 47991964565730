import { withAuthenticator, Table, TabItem,Tabs,    TableBody,    TableCell,    TableHead,    TableRow, Card, Grid, ThemeProvider, Theme, SearchField, Flex, Link, Button  } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import { useSearchParams, useParams } from "react-router-dom";
import { API, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import './css/ViewEvent.css';
import { getMarketingEvent as getMarketingEventQuery } from '../graphql/queries';
import moment from 'moment';


const theme: Theme = {
    name: 'table-theme',
    tokens: {
      components: {
        table: {
          row: {
            hover: {
              backgroundColor: { value: '{colors.blue.20}' },
            },
  
            striped: {
              backgroundColor: { value: '{colors.blue.10}' },
            },
          },
  
          header: {
            color: { value: '{colors.blue.80}' },
            fontSize: { value: '{fontSizes.xl}' },
          },
  
          data: {
            fontWeight: { value: '{fontWeights.semibold}' },
          },
        },
      },
    },
  };

function ViewEvent({ signOut, user }) {
  const [marketingEvent, setMarketingEvent] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  //const userInfo = Auth.currentAuthenticatedUser();


  
  useEffect(() => { 
    fetchEvent();
  }, []); 

  async function fetchEvent() { 
    //alert(params.id);
    //alert(user.username);
    console.log(user);
    const apiData = await API.graphql({ query: getMarketingEventQuery, variables: { id: params.id } });
    setMarketingEvent(apiData.data.getMarketingEvent);
  }

  return (
    <ThemeProvider theme={theme} colorMode="light">

    <div className="App">
      <h1>Subaru Marketing Event</h1>
      <h2>{marketingEvent.eventShortName}</h2>
      <h2>{user.username}</h2>
      <h2>{user.id}</h2>
      <h2>{user.attributes.email}</h2>
      <Tabs className="event-tab">
  <TabItem title="Event Info">
  <Card >
        <Grid templateColumns="1fr 1fr 1fr 1fr" className="custom-card-class">
        <Card className="fieldLabel">Event Creator</Card><Card  className="fieldValue">{marketingEvent.eventCreator}</Card>
<Card className="fieldLabel">Event Type</Card><Card  className="fieldValue">{marketingEvent.eventType}</Card>
<Card className="fieldLabel">Event Short Name</Card><Card  className="fieldValue">{marketingEvent.eventShortName}</Card>
<Card className="fieldLabel">Event Title</Card><Card  className="fieldValue">{marketingEvent.eventTitle}</Card>
<Card className="fieldLabel">Vehicles On Display</Card><Card  className="fieldValue">{marketingEvent.vehicleOnDisplay}</Card>
<Card className="fieldLabel">Event Venue Name</Card><Card  className="fieldValue">{marketingEvent.eventVenueName}</Card>
<Card className="fieldLabel">Address Line 1</Card><Card  className="fieldValue">{marketingEvent.eventAddressLine1}</Card>
<Card className="fieldLabel">Address Line 2</Card><Card  className="fieldValue">{marketingEvent.eventAddressLine2}</Card>
<Card className="fieldLabel">City</Card><Card  className="fieldValue">{marketingEvent.eventAddressCity}</Card>
<Card className="fieldLabel">State</Card><Card  className="fieldValue">{marketingEvent.eventAddressState}</Card>
<Card className="fieldLabel">Zip Code</Card><Card  className="fieldValue">{marketingEvent.eventAddressZipCode}</Card>


        </Grid>
      </Card>
  </TabItem>
  <TabItem  title="Audit Information">
  <Card >
        <Grid templateColumns="1fr 1fr 1fr 1fr" className="custom-card-class">
        <Card className="fieldLabel">Created By</Card><Card  className="fieldValue">{marketingEvent.createdByUser}</Card>
        <Card className="fieldLabel">Last Modified By</Card><Card  className="fieldValue">{marketingEvent.modifiedByUser}</Card>
        <Card className="fieldLabel">Created At</Card><Card  className="fieldValue">{moment(marketingEvent.createdAt).format('MM/DD/YY h:mm:ss a')}</Card>
        <Card className="fieldLabel">Last Modified Time</Card><Card  className="fieldValue">{moment(marketingEvent.updatedAt).format('MM/DD/YY h:mm:ss a')}</Card>


        </Grid>
      </Card>
  </TabItem>

</Tabs>
      

    </div></ThemeProvider>);
}

export default withAuthenticator(ViewEvent);