/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTypeEnumeration = /* GraphQL */ `
  query GetTypeEnumeration($name: String!) {
  __type(name: $name) {
    name
    enumValues {
      name
      description
      isDeprecated
    }
  }
}
`;
