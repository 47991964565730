import { withAuthenticator,  Table,    TableBody,    TableCell,    TableHead,    TableRow, ThemeProvider, Theme, SearchField, Flex, Link, Button  } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import { API, Amplify, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import '../App.css';
import { deleteMarketingEvent as deleteMarketingEventMutation } from '../graphql/mutations';
import { listMarketingEvents as listMarketingEventsQuery } from '../graphql/queries';

Amplify.configure({
    API: {
        aws_appsync_graphqlEndpoint: 'https://njoruudhkjhz5hdzhcxaoye3gi.appsync-api.us-east-1.amazonaws.com/graphql',
        aws_appsync_region: 'us-east-1',
        aws_appsync_authenticationType: 'NONE',
        graphql_headers: async () => ({
            'Authorization': 'Bearer '+(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken})
    }
  });

const theme: Theme = {
    name: 'table-theme',
    tokens: {
      components: {
        table: {
          row: {
            hover: {
              backgroundColor: { value: '{colors.blue.20}' },
            },
  
            striped: {
              backgroundColor: { value: '{colors.blue.10}' },
            },
          },
  
          header: {
            color: { value: '{colors.blue.80}' },
            fontSize: { value: '{fontSizes.xl}' },
          },
  
          data: {
            fontWeight: { value: '{fontWeights.semibold}' },
          },
        },
      },
    },
  };

function ViewEvents({ signOut, user }) {
  const [marketingEvents, setMarketingEvents] = useState([]);

  useEffect(() => { 
    fetchEvents();
  }, []); 




  async function fetchEvents() {
    const apiData = await API.graphql({ query: listMarketingEventsQuery });
    setMarketingEvents(apiData.data.listMarketingEvents.items);
  }

   async function refetchEvents() {
    const apiData = await API.graphql({ query: listMarketingEventsQuery });
    setMarketingEvents(apiData.data.listMarketingEvents.items);
  }
  
  async function deleteMarketingEvent({ id }) {
    const newMarketingEventsArray = marketingEvents.filter(marketingEvent => marketingEvent.id !== id);
    setMarketingEvents(newMarketingEventsArray);
    await API.graphql({ query: deleteMarketingEventMutation, variables: { input: { id } }});
  }

  return (
    <ThemeProvider theme={theme} colorMode="light">
        {    console.log(user)    }
        {console.log(Auth.currentAuthenticatedUser())}
    <div className="App">
      <h1>Subaru Marketing Events</h1>
      <Flex>          
      <Link href="/createNewEvent"><Button>Create a New Event</Button></Link>
      <Button onClick={() => refetchEvents()} ><i aria-hidden="true" className={`refresh icon`}></i>
                                &nbsp;&nbsp;&nbsp;Refresh Event List</Button>
      <SearchField label="search" placeholder="Search..." />
      </Flex>
      <Table highlightOnHover={true} variation="striped">
    <TableHead>
      <TableRow>
        <TableCell as="th" colSpan="1">Event Short Name</TableCell>
        <TableCell as="th" colSpan="1">Event Type</TableCell>
        <TableCell as="th" colSpan="1">Event Creator</TableCell>
        <TableCell as="th" colSpan="1">Delete Event</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
          {
          marketingEvents.map(marketingEvent => (
            <TableRow key={marketingEvent.id || marketingEvent.name}>
            <TableCell><Link href={`/event/${marketingEvent.id}`}>{marketingEvent.eventShortName}</Link></TableCell>
            <TableCell>{marketingEvent.eventType}</TableCell>
            <TableCell>{marketingEvent.eventCreator}</TableCell>
            <TableCell><button onClick={() => deleteMarketingEvent(marketingEvent)}>Delete</button></TableCell>
            </TableRow>
          ))
          }
    </TableBody>
  </Table>
    </div></ThemeProvider>);
}

export default withAuthenticator(ViewEvents);