import { withAuthenticator, Flex, View, TextField, Text, Grid,Card, useTheme, Theme, ThemeProvider } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import { API, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import '../App.css';
import { createMarketingEvent as createMarketingEventMutation } from '../graphql/mutations';

const initialFormState = { createdByUser : '', modifiedByUser : '' }


function CreateNewEvent({ signOut, user }) {
    initialFormState.createdByUser = user.username;
    initialFormState.modifiedByUser = user.username;
    //alert(initialFormState.requesterName);
//    , modifiedByUser : user.username };
    const [marketingEvents, setMarketingEvents] = useState([]);
    const [formData, setFormData] = useState(initialFormState);

    //setFormData({...formData, createdByUser: user.username});
    //setFormData({...formData, modifiedByUser: user.username});

    useEffect(() => {
    }, []);

  
    function createTextInput(fieldId, fieldName, fieldDescription){
        return(<TextField
            alignItems="baseline"
            name={fieldId}
            id={fieldId}
            descriptiveText={
                <Text
                    as="span"
                    color="rebeccapurple"
                    fontStyle="italic"
                    fontSize="0.8rem"
                >{fieldDescription}</Text>
            }
            placeholder={fieldName}
            label={fieldName} onChange={e => setFormData({ ...formData, [fieldId]: e.target.value })}
            errorMessage="There is an error"
        />)
    };

    async function createMarketingEvent() {
        //formData.name="test";
        //formData.description="test";
        //formData.emailRequestStatus = "RECEIVED";
        //if (!formData.name || !formData.description) return;
        await API.graphql({ query: createMarketingEventMutation, variables: { input: formData } });
        setMarketingEvents([...marketingEvents, formData]);
        setFormData(initialFormState);
    }

    const { tokens } = useTheme();

    const theme: Theme = {
        name: 'textfield-theme',
        tokens: {
          components: {
            textfield: {
              color: { value: '{colors.blue.90}' },
              _focus: {
                borderColor: { value: '{colors.blue.40}' },
              },
            },
          },
        },
      };


    return (
        <ThemeProvider theme={theme} colorMode="light">
        <div className="App">
            <h1>My Notes App</h1>
            <View>
                <Flex direction="column">
                    {
                        /*<SelectField
                          label="Email Request Status"
                          descriptiveText="Please select a status."
                          options={enums.map(item => item.name)}
                          value={formData.emailRequestStatus}
                          onChange={e => setFormData({ ...formData, 'emailRequestStatus': e.target.value})}
                        ></SelectField>*/
                    }
                </Flex>
            </View>
            <Grid alignItems="center"
                justify="center" templateColumns="1fr 1fr" width="800px"
                templateRows="10rem 10rem"
                gap={tokens.space.small}>
<Card>{createTextInput('eventCreator', 'Event Creator', 'Who is creating this event?')}</Card>
<Card>{createTextInput('eventType', 'Event Type', 'What type of event are you hosting?')}</Card>
<Card>{createTextInput('eventShortName', 'Event Short Name', 'Please use a name that is easily recognizable.')}</Card>
<Card>{createTextInput('eventTitle', 'Event Title', 'This should be the name you want to appear as the title of the event on the events page, in email and other customer facing materials.')}</Card>
<Card>{createTextInput('vehicleOnDisplay', 'Vehicles On Display', '')}</Card>
<Card>{createTextInput('eventVenueName', 'Event Venue Name', '')}</Card>
<Card>{createTextInput('eventAddressLine1', 'Address Line 1', '')}</Card>
<Card>{createTextInput('eventAddressLine2', 'Address Line 2', '')}</Card>
<Card>{createTextInput('eventAddressCity', 'City', '')}</Card>
<Card>{createTextInput('eventAddressState', 'State', '')}</Card>
<Card>{createTextInput('eventAddressZipCode', 'Zip Code', '')}</Card>
            </Grid>

            <button onClick={createMarketingEvent}>Create Note</button>
            <button onClick={signOut}>Sign Out</button>
        </div></ThemeProvider>);
}

export default withAuthenticator(CreateNewEvent);