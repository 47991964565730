import { React, useState } from "react";
import { withAuthenticator, MenuItem, Menu, Grid, useTheme, View } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import { BrowserRouter, Route, Link } from "react-router-dom";
import { Auth } from "aws-amplify";




function Navbar({ signOut, user }) {
    const { tokens } = useTheme();
    const [loggedIn, setLoggedIn] = useState(false);
    const [userGroups, setUserGroups] = useState();


    const assessLoggedInState = () => {
        Auth.currentAuthenticatedUser().then(sess => 
            { 
                console.log('logged in'); 
                setLoggedIn(true);

            }).catch(() => 
                        { 
                            console.log('not logged in'); setLoggedIn(false); 
                        })
    }

    const assessUserGroups = () => {
        Auth.currentAuthenticatedUser().then(sess => {                 
        const userGroups = JSON.stringify(sess.signInUserSession.accessToken.payload["cognito:groups"]);
        console.log('userGroups: '+userGroups); 
        setUserGroups(userGroups); 
     })
            .catch(err => { console.log('error setting groups' + err); setUserGroups({}); })
    }

    //alert("Logged In: " + loggedIn + " User Groups " + userGroups);

    assessLoggedInState();
    assessUserGroups();

    function displayViewLink(linkTo, description) {
        return (<View backgroundColor={tokens.colors.blue[20]} ><Link to={linkTo}>{description}</Link></View>)
    };
    return (
        <nav>
            <Grid style={{ textAlign: 'center' }}
                templateColumns="1fr 1fr  1fr"
                gap={tokens.space.small}
            >
                {displayViewLink("/", "Home")}
                {/*if(user.signInUserSession.accessToken.payload["cognito:groups"].contains("MST-Event-Manager")){displayViewLink("/createNewEvent", "Create New Event")}*/}
                {displayViewLink("/viewEvents", "View Existing Events")}

                {/*<Link onClick={signOut}>Sign Out</Link>*/}

            </Grid>
        </nav>
    );
}

export default Navbar;


